import { getRequest,deleteRequest ,postRequest} from "@/http/request_service.js";
import { API } from "@/api/api.js"

export const roleList=(params)=>{
    return getRequest(API.getRolelist,params);
}

export const operationRole=(params)=>{
    return deleteRequest(API.operationRole+'/'+params);
}

export const operationRoles=(params)=>{
    return getRequest(API.operationRole+'/'+params);
}

export const getAllrole=(params)=>{
    return getRequest(API.getAllrole,params);
}

export const getAllpermissions=(params)=>{
    return getRequest(API.getAllpermissions,params);
}

export const roleEdit=(params)=>{
    return postRequest(API.roleEdit,params);
}