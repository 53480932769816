<template>
  <div class="mainwrap privacylist" v-loading="loading">
    <div class="title">
      角色管理
       <div class="addbtn" v-if="permissionType === 2">
        <el-button  type="success" @click="toAddBanner">新增</el-button>
      </div>
    </div>
    <div class="searchform">
      <el-form
          label-width="82px"
          :inline="true"
          :model="searchForm"
          class="demo-form-inline"
      >

        <el-form-item label="角色名稱">
          <el-input
              v-model="searchForm.name"
              placeholder="請輸入角色名稱"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="name" label="角色名"> </el-table-column>
        <el-table-column prop="lastModificationTime" label="更新時間"> </el-table-column>
        <el-table-column label="操作" v-if="permissionType === 2">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="primary" plain>
              编辑
            </el-button>
            <div class="delbtn">
              <el-popconfirm
              confirm-button-text='確定'
              cancel-button-text='取消'
              confirm-button-type="danger"
              icon="el-icon-info"
              icon-color="red"
              title="確定刪除？"
              @confirm="deleteRole(scope.row.id)"
            >
                <el-button type="danger" slot="reference">刪除</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
          background
          :hide-on-single-page="isSinglePage"
          layout="prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :total="tableDataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';

export default {
  name: 'authorityManagement',
  data(){
    return{
      permissionType : -1,
      loading: false,
      isSinglePage: false,
      currentPage: 1,
      tableDataTotal: 100,
      searchForm:{
        name:"",
        maxResultCount: 10
      },
      options:[],
      // tableData: []
      tableData: [
        {
          id:'1',
          memberName:'1'
        }
      ]

    }
  },
  methods:{
    reSet(){
      this.searchForm={
        name:"",
        maxResultCount: 10
      }
      this.currentPage=1;
      this.getList();
    },
     // 新增
    toAddBanner() {
      this.$router.push({
        path: "/cms/authoritymanagementedit",
        query: {
          type: "create",
        },
      });
    },
     // 編輯
    handleEdit(item) {
      this.$router.push({
        path: "/cms/authoritymanagementedit",
        query: {
          type: "edit",
          id: item.id,
        },
      });
    },
    // 翻頁
    handleCurrentChange(val) {
      this.currentPage=val;
      this.getList()
    },
    // 查詢
    onSearch() {
      this.currentPage=1;
      this.getList();
    },
    // 刪除
    deleteRole(id){
      api.operationRole(id).then(res=>{
        if(res.systemCode===200){
          this.$message.success('刪除成功');
          if (this.tableData.length <= 1) {
              this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;
          }
          this.getList();
        }
      })
    },
    getList() {
      this.loading = true;
      api.roleList({
        // Sorting: this.searchForm.sorting,
        name:this.searchForm.name,
        SkipCount: (this.currentPage - 1) * this.searchForm.maxResultCount,
        MaxResultCount: this.searchForm.maxResultCount
      }).then(res=>{
        if(res.systemCode===200){
          let resarr = res.data.items;
          resarr.forEach(item => {
            if(!item.lastModificationTime) {
              item.lastModificationTime = item.creationTime
            }
          });
          this.tableData = resarr;
          this.tableDataTotal = res.data.totalCount
        }
        this.loading = false;
      })
    },
  },
  created() {
    this.getList()
    this.permissionType = parseInt(getStore("permissionType"))
  },
  activated(){
    this.getList();
  }
}
</script>

<style lang="scss" scoped>
.privacylist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .addbtn {
    width: 100px;
    float: right;
  }

  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
